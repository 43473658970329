// src/App.js
import React from "react";
import { Route, Routes } from "react-router-dom";
import Companies from "./Companies";
import Company from "./Company";
import Templates from "./Templates";

const App = () => {

  return (     
    <Routes>
      <Route path="/company/:companyUrl/template/:templateid" element={<Templates />} />
      <Route path="/company/:companyUrl/templates" element={<Templates />} />
      <Route path="/company/:companyUrl/:contactid" element={<Company />} />
      <Route path="/company/:companyUrl" element={<Company />} />
      <Route path="/" element={<Companies />} />
    </Routes>
  );
};

export default App;
