export const FRONT_URL = process.env.REACT_APP_FRONT_URL;
export const API_URL = process.env.REACT_APP_API_URL;
export const STATIC_URL = process.env.REACT_APP_STATIC_URL;
export const ENV = process.env.REACT_APP_ENV;

// App Configurations
export const APP_NAME = "Atria";
export const CONFIG_DEFAULT_DAYS_BACK_CONVERSATIONS = 8;
export const CONFIG_TIME_CONVERSATION_WINDOW = 24*60*60*1000 - 10*60*1000;//24 Hours - 10 minutes gap to consider processing times
export const PATH_POWER_ON = "on";
export const PATH_POWER_OFF = "off";
export const PATH_SEND = "send";
export const PATH_RESET = "reset";
export const PATH_REPLICATE = "replicate";
export const PATH_TAGS = "tags";
export const PATH_TAG = "tag";
export const PATH_UNTAG = "untag";
export const PATH_REMARKETING = "remarketing";