import React, { useEffect, useState, useRef } from "react";
import { useAuth } from "@clerk/clerk-react";
import { API_URL,PATH_RESET,PATH_TAG } from "./utils/constants";

const ConversationHeader = ({ conversation, handlerUpdate, tags }) => {
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState(null);
  
  const dropdownRef = useRef(null);
  const { getToken } = useAuth(); // Get the token

  const toggleMenu = () => {
    setVisible(!visible);
  }

  const reset = async () => {
    try {

      const resetConfirmation = window.confirm('Reiniciar conversacion?');
      if (!resetConfirmation) return;

      const token = await getToken(); // Get the token
      const response = await fetch(`${API_URL}company/${conversation?.contact?.company}/${conversation?.contact?.contactid}/${PATH_RESET}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      });
      if(response.status === 200){
        setVisible(false);
        console.log("RESET "+conversation?.contact?.contactid);
        handlerUpdate();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const tagConversation = async (tag) => {
    try {
      const token = await getToken(); // Get the token
      const response = await fetch(`${API_URL}company/${conversation.contact?.company}/${conversation.contact?.contactid}/${PATH_TAG}/${tag}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      });
      if(response.status === 200){
        setVisible(false);
        console.log("TAG "+tag);
        handlerUpdate();
      }
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setVisible(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  

  if (error) {
    return <div>Error</div>;
  }

  return (
    <div className="ml-4 flex-none">
          {/* Dots button */}
          <button 
            id="dropdownButton"
            className="text-gray-500 focus:outline-none"
            onClick={toggleMenu}>
            <svg className="w-6 h-6" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M10 3a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM10 8.5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM11.5 15.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0z"></path>
            </svg>
          </button>
          {/* /Dots button */}
        
          {/* Dropdown menu */}
          <div id="dropdownMenu"
            className={`absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg z-20 ${visible?'':'hidden'}`}
            ref={dropdownRef}>

            <a href="#" onClick={(e) => { e.preventDefault(); reset(); }} className="block px-4 py-2 text-gray-800 hover:bg-gray-100">Reiniciar</a>

            {(tags && Array.isArray(tags) && tags.length > 0)?
              tags.map((tag) => (
                <a href="#" className="block px-4 py-2 text-gray-800 hover:bg-gray-100" key={tag.url} onClick={(e) => { e.preventDefault(); tagConversation(tag.url); }}>
                  <div className="inline-block h-2 w-2 rounded-full mr-1" style={{background:tag.backgroundColor}}></div>Etiquetar {tag.name}
                </a>
              ))
            :<></>}

          </div>
          {/* /Dropdown menu */}
        </div>
  );
};

export default ConversationHeader;
