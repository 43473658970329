import React, { useEffect, useState } from "react";
import { useAuth } from "@clerk/clerk-react";
import { shortFormat} from "./utils/dateHelper";
import { messageFormat} from "./utils/textHelper";
import { useParams } from "react-router-dom";
import { API_URL,PATH_REPLICATE } from "./utils/constants";

const ConversationMessage = ({key, message, handlerUpdate}) => {
  const [replicating, setReplicating] = useState(false);
  const [error, setError] = useState(null);
  const {companyUrl, contactid } = useParams(); //Param
  const { getToken } = useAuth(); // Get the token

  const replicate = async (messageid) => {
    try {
      const confirmed = window.confirm('Replicar este mensaje?');
      if (!confirmed) return;

      setReplicating(true);

      const token = await getToken(); // Get the token
      const response = await fetch(`${API_URL}company/${companyUrl}/${contactid}/${PATH_REPLICATE}/${messageid}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      });
      if(response.status === 200){
        console.log("REPLICATE "+contactid);
        setReplicating(false);
        handlerUpdate();
      }
    } catch (error) {
      setError(error.message);
    }
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (message.type === "request") {
  return (

      <div className="col-start-1 col-end-10 md:col-end-8 p-3 rounded-lg"  key={message._id}>
        <div className="flex flex-row items-center">
          <div className="relative ml-3 text-sm bg-white py-2 px-4 shadow rounded-xl">
            <div>

              {message.replied_message ?
                <p 
                className="mt-1 mb-2 py-2 px-2 shadow bg-gray-100 rounded-xl"
                dangerouslySetInnerHTML={{ __html: messageFormat(message.replied_message) }} />
                :
                <></>
              }

              {message.referral ?
                <p 
                className="mt-1 mb-2 py-2 px-2 shadow bg-gray-100 rounded-xl"
                dangerouslySetInnerHTML={{ __html: messageFormat(`[${message.referral}](${message.referral})`) }} />
                :
                <></>
              }

              <p dangerouslySetInnerHTML={{ __html: messageFormat(message.message) }} />


              {message.info?.media ?
                <>
                  {message.info.media?.map((media) => (
                    <>
                      {(media.type === 'audio') ?
                        <audio controls className="mt-2">
                          <source src={media.url} type="audio/ogg" />
                        </audio>
                        : <></>}
                    </>
                  ))}
                </>
                :
                <>
                  {(message.info && typeof message.info.audio !== 'undefined') ?
                    <audio controls className="mt-2">
                      <source src={message.info.audio} type="audio/ogg" />
                    </audio>
                    : <></>}
                </>
              }

            </div>
          </div>
        </div>
        <div className="text-xs bottom-0 left-0 mb-2 mt-2 ml-3 text-gray-500">

          {shortFormat(message.createdAt)}

          {message.info?.media ?
            <>
            {message.info.media?.map((media) => (
              <>
              {(media.type === 'audio' )?<a href={media.url} title="Audio">🔊</a>:<></>}
              {(media.type === 'image')?<a href={media.url} title="Image">📷</a>:<></>}
              </>
            ))}
            </>
          :
          <>
            {(message.info && typeof message.info.audio !=='undefined' )?<a href={message.info.audio} title="Audio">🔊</a>:<></>}
            {(message.info && typeof message.info.video !=='undefined' )?<a href={message.info.video} title="Video">📽️</a>:<></>}
            {(message.info && typeof message.info.document !=='undefined')?<a href={message.info.document} title="Document">📄</a>:<></>}
            {(message.info && typeof message.info.image !=='undefined' )?<a href={message.info.image} title="Image">📷</a>:<></>}
            {(message.info && typeof message.info.contact !=='undefined' )?<a href={message.info.contact} title="Contact Card">🪪</a>:<></>}
            {(message.info && typeof message.info.location !=='undefined' )?<a href={"https://www.google.com/maps/search/?api=1&query="+message.info.location} title="Location">📍</a>:<></>}
          </>
          }
          
          {!replicating?
          <a href="#" onClick={(e) => { e.preventDefault(); replicate(message._id); }} title="Replay" className="ml-1">
            <svg className="inline" fill="none" height="12"  width="12" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><polygon points="5 3 19 12 5 21 5 3"/></svg>
          </a>
          :<span className="ml-1">Replicando...</span>}

          {(message.status)?<abbr title={`Status: ${message.status}`} className="mr-2">🔀{message.status}</abbr>:<></>}

        </div>
      </div>
    );
  }
  else if(message.type === "response" || message.type === "outbound" || message.type === "response-off"){
    return(
      <div className="col-start-2 md:col-start-6 col-end-13 p-3 rounded-lg" key={message._id}>
          <div className="flex items-center justify-start flex-row-reverse">
            <div className={`w-full mr-3 text-sm ${(message.type === "response-off")?`bg-gray-300`:`bg-green-100`} py-2 px-4 shadow rounded-xl`}>
              <p dangerouslySetInnerHTML={{ __html: messageFormat(message.message) }} />
            </div>
          </div>
          <div className="text-xs bottom-0 right-0 mb-2 mt-2 mr-2 text-gray-500 text-right">
          {(message.status)?<abbr title={`Status: ${message.status}`} className="mr-2">🔀{message.status}</abbr>:<></>}
            {(message.type === "response-off")?<span className="mr-2">No visible. Agente Apagado</span>:<></>}
            {(message.info && typeof message.info.user !=='undefined' )?<><abbr title={message.info?.user}>👤</abbr><span className="inline sm:hidden">{typeof message.info?.user === 'string' ? message.info?.user : ''}</span></> :<abbr title="Agent">🤖</abbr> }
            <span className="ml-2">{shortFormat(message.createdAt)}</span>
          </div>
      </div>
    );
  }
  else if(message.type === "action"){
    return(
      <div className="col-start-2 md:col-start-6 col-end-13 p-3 rounded-lg" key={message._id}>
          <div className="flex items-center justify-start flex-row-reverse">
            <div className=" w-full mr-3 text-sm bg-gray-300 py-2 px-4 shadow rounded-xl">
                <p>{
                  (() => {
                    try {
                      const parsedMessage = JSON.parse(message.message);
                      if (parsedMessage.action === "etiqueta") {
                        return "Etiqueta: " + parsedMessage.tag;
                      }
                      return parsedMessage.action;
                    } catch (error) {
                      console.error("Error parsing message:", error);
                      return message.message;
                    }
                  })()
                }</p>
            </div>
          </div>
          <div className="text-xs bottom-0 right-0 mb-2 mt-2 mr-2 text-gray-500 text-right">
            {(message.status)?<abbr title={`Status: ${message.status}`} className="mr-2">🔀{message.status}</abbr>:<></>}
            {(message.info && typeof message.info.user !=='undefined' )?<><abbr title={message.info?.user}>👤</abbr><span className="inline sm:hidden">{typeof message.info?.user === 'string' ? message.info?.user : ''}</span></> :<abbr title="Agent">🤖</abbr> }
            <span className="ml-2">{shortFormat(message.createdAt)}</span>
          </div>
      </div>
    );
  }
  else{
    return(
      <div className="col-start-2 md:col-start-6 col-end-13 p-3 rounded-lg" key={message._id}>
          <div className="flex items-center justify-start flex-row-reverse">
            <div className=" w-full mr-3 text-sm bg-green-100 py-2 px-4 shadow rounded-xl">
              <p>{(message.message)?message.message:`{{Mensaje vacío}}`}</p>
            </div>
          </div>
          <div className="text-xs bottom-0 right-0 mb-2 mt-2 mr-2 text-gray-500 text-right">
            {(message.info && typeof message.info.user !=='undefined' )?<><abbr title={message.info?.user}>👤</abbr><span className="inline sm:hidden">{typeof message.info?.user === 'string' ? message.info?.user : ''}</span></> :<abbr title="Agent">🤖</abbr> }
            {shortFormat(message.createdAt)}
          </div>
      </div>
    );
  }
};

export default ConversationMessage;
